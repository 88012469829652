import { useEffect } from "react"
import { googleSignIn } from "../../hooks/useLogin"
import { GoogleButton } from "react-google-button"
import { useNavigate } from "react-router-dom"
//import { useAuthContext } from "../../hooks/useAuthContext"

export default function Login() {
  const navigate = useNavigate()
  //const { user } = useAuthContext()

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      {/* <h2>Login</h2> */}
      {/* <form onSubmit={handleSubmit}>
        <label>
          <span>email:</span>
          <input required type="email" onChange={e => setEmail(e.target.value)} value={email} />
        </label>
        <label>
          <span>password:</span>
          <input required type="password" onChange={e => setPassword(e.target.value)} value={password} />
        </label>
        <button className="btn">log in</button>
        {error && <p>{error}</p>}
      </form> */}
      <span style={{ display: "flex", paddingTop: "5em" }}>
        <span style={{ marginLeft: "auto", marginRight: "auto" }}>
          <GoogleButton onClick={handleGoogleSignIn} />
        </span>
      </span>
    </div>
  )
}
