import { BrowserRouter, Route, Routes } from "react-router-dom"
//import { useAuthContext } from "./hooks/useAuthContext"
import { AuthContextProvider } from "./context/AuthContext"
import { UserAuth } from "./context/AuthContext"

// page components
import Home from "./pages/home/Home"
import Lego from "./pages/lego/Lego"
import Cards from "./pages/cards/Cards"
import CardDetail from "./pages/cards/CardDetail"
import Navbar from "./components/Navbar"
import Tools from "./pages/tools/Tools"
import Login from "./pages/login/Login"
import Signin from "./signin/Signin"
import Search from "./pages/search/Search"
import LegoDetail from "./pages/lego/LegoDetail"
import CardWishlist from "./pages/wishlist/CardWishlist"

// import ThemeSelector from "./components/ThemeSelector"

// styles
import "./App.css"


//import { useTheme } from "./hooks/useTheme"

function App() {
  //const { user, authIsReady } = useAuthContext()
  //const { mode } = useTheme()
  const { user } = UserAuth()

  return (
    <div className="App dark">
      <AuthContextProvider>
        {/* {authIsReady && ( */}
        <BrowserRouter>
          <Navbar />
          {/* <ThemeSelector /> */}
          <div className="Container">
            <Routes>
              <Route path="/" element={user ? <Home /> : <Signin />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/lego" element={user ? <Lego /> : <Signin />} />
              <Route path="/lego/:id" element={user ? <LegoDetail /> : <Signin />} />
              <Route path="/cards" element={user ? <Cards /> : <Signin />} />
              <Route path="/cards/:id" element={user ? <CardDetail /> : <Signin />} />
              <Route path="/tools" element={user ? <Tools /> : <Signin />} />
              <Route path="/search" element={user ? <Search /> : < Signin /> } />
              <Route path="/cards/wishlist" element={user ? <CardWishlist /> : <Signin />} />
              {/* <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/lego" element={<Lego />} />
              <Route path="/cards" element={<Cards />} />
              <Route path="/cards/:id" element={<CardDetail />} />
              <Route path="/tools" element={<Tools />} /> */}
              {/* <Route path="/create" element={<Create />} />
          <Route path="/search" element={<Search />} /> */}
            </Routes>
          </div>
        </BrowserRouter>
        {/* )} */}
      </AuthContextProvider>
    </div>
  )
}

export default App
