import { useNavigate } from "react-router-dom"
import { UserAuth } from "../context/AuthContext"
import { Link } from "react-router-dom"
import Searchbar from "./Searchbar"
import { useLocation } from "react-router-dom"

//styles
import "./Navbar.css"

export default function Navbar() {
  const { user, logOut } = UserAuth()
  const navigate = useNavigate()

  const location = useLocation()
  const pathname = location.pathname
  console.log("Navbar pathname: ", pathname)
  let page = ""
  if (pathname == "/cards") 
    page = "Cards"
  console.log("navbar page: ", page)

  const handleSignOut = async () => {
    try {
      await logOut()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="navbar">
      <nav>
        <Link to="/" className="brand">
          <h1>Collection</h1>
        </Link>
        {/* <Searchbar page={page}/> */}
        <Link to="/lego">Lego</Link>
        <Link to="/cards">Cards</Link>
        {/* <p>user: {user?.displayName}</p> */}
        {user?.displayName ? <a onClick={handleSignOut}>sign out</a> : <Link to="/signin">Sign in</Link>}
      </nav>
    </div>
  )
}
