import { useState } from "react"
import { db } from "../../firebase/config"
import { collection, doc, addDoc, query, where, getDocs } from "firebase/firestore"

//styles
//import "./Create.css"


export default function Create(props) {


    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [theme, setTheme] = useState('')
    const [year, setYear] = useState('')
    const [quantity, setQuantity] = useState('')

    const handleSubmit = async(e) => {
        e.preventDefault()
        // Save
        const ref = collection(db, "LegoSets")

        // let dateFormat = new Date(dueDate)
        // dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
        // let dateStr = dateFormat.toDateString()
    
        await addDoc(ref, {
          name: name.trim(),
          number: number.trim(),
          theme: theme.trim(),
          year: +year.trim(),
          quantity: +quantity.trim()
        })

        // Close popup
        props.toggle()
    }

    return (
        <div className="popup">
            <div className="popup-inner">
                {/* <p>Add a Lego Set</p> */}
                <form onSubmit={handleSubmit}>
                    <label>
                        Name:
                        <input type="text" value={name} onChange={e => setName(e.target.value)} />
                    </label>
                    <label>
                        Number:
                        <input type="text" value={number} onChange={e => setNumber(e.target.value)} />
                    </label>
                    <label>
                        Theme:
                        <input type="text" value={theme} onChange={e => setTheme(e.target.value)} />
                    </label>
                    <label>
                        Year:
                        <input type="number" value={year} onChange={e => setYear(e.target.value)} />
                    </label>
                    <label>
                        Quantity:
                        <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} />
                    </label>
                    <button type="submit">Submit</button>
                </form>
                <button type="button" onClick={props.toggle}>Close</button>
            </div>
        </div>
    )
}
