import { useCollection } from "../../hooks/useCollection"
import Searchbar from "../../components/Searchbar"
import { collection, addDoc, query, where, onSnapshot, deleteDoc, orderBy, or, limit, startAfter, getDocs } from "firebase/firestore"
import { db, doc, Timestamp } from "../../firebase/config"
import { useState, useEffect } from "react"
import { useMediaQuery } from "../../hooks/useMediaQuery"

//styles
// import "./Lego.css"

import CardsList from "./CardsList"

export default function Cards() {
  let isPageWide = useMediaQuery("(min-width: 800px)")

  const [term, setTerm] = useState()
  const [searchValue, setSearchValue] = useState()
  const [cards, setCards] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()
    alert("Handle Submit")

    setSearchValue(term)
  }

  // useEffect(() => {
  //   async function fetchData() {
  //     // You can await here
  //     const first = query(collection(db, "Cards"), orderBy("certification"), limit(3));
  //     const documentSnapshots = await getDocs(first);
  //     // ...
  //   }
  //   fetchData();
  // }, []);

  useEffect( () => {
    let ref = collection(db, "Cards")
    ref = query(ref, orderBy("certification"),limit(4))

    if (searchValue) {
      console.log("searchValue", searchValue)

      ref = query(ref, or(where(...["search", "==", searchValue]),
        or(where(...["name", "==", searchValue])),
        or(where(...["grade", "==", searchValue]))
      ), orderBy("name"))
    }

    const unsub = onSnapshot(ref, snapshot => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ id: doc.id, ...doc.data() })
      })
      //results.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      setCards(results)
      console.log("Results:", results)
    })

    return () => unsub()
  }, [searchValue])

  //const { documents: cards } = useCollection("Cards")
  console.log("Cards:", cards)

  return (
    <div>
      {/* <label>
        Search:
        <input type="text" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
      </label> */}
      <div className="list">
      <form style={isPageWide === true ? {display:"flex", marginLeft: "auto", width: "30%"} : {display:"flex", marginLeft: "auto", width: "100%"}} onSubmit={handleSubmit}>
        <label style={{padding: "8px", color: "#fff"}} htmlFor="search">Search:</label>
        <input
          id="search"
          type="text"
          onChange={(e) => setTerm(e.target.value)}
          required
        />
      </form>
      </div>

      {/* <Searchbar page={"Cards"} /> */}
      <CardsList allCards={cards} />

    </div>
  )
}
