import { useCollection } from "../../hooks/useCollection"
import { useState } from "react"
import Searchbar from "../../components/Searchbar"

//styles
import "./Lego.css"
import LegoList from "./LegoList"

export default function Lego() {
  const { documents: legoSets } = useCollection("LegoSets")
  console.log("Lego Sets:", legoSets)

  // const [seen, setSeen] = useState(false)

  // const togglePop = () => {
  //   setSeen(!seen)
  // }

  return (
    <div>
      <Searchbar page={"Lego"} />
      <LegoList legoSets={legoSets} />

      {/* <button onClick={togglePop}>Login</button>
      {seen ? <Create toggle={togglePop} /> : null} */}
    </div>
  )
}
