import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
//import { useTheme } from '../hooks/useTheme'
import { useMediaQuery } from "../../hooks/useMediaQuery"

// styles
import "./CardsList.css"

import Create from "./Create"

export default function CardsList({ allCards }) {
  console.log("all cards:", allCards)
  let isPageWide = useMediaQuery("(min-width: 800px)")
  console.log("isPageWide:", isPageWide)

  const [show, setShow] = useState(false)
  const [myFilterType, setMyFilterType] = useState(null)
  const [myFilterValue, setMyFilterValue] = useState(null)

  //const [cards, setCards] = useState(null)
  //let cards = allCards
  let cards = null
  if (myFilterValue !== null) {
    switch (myFilterType) {
      case "Name":
        //cards = allCards.filter(f => f.name === myFilterValue)
        cards = allCards.filter(f => f.name.includes(myFilterValue))
        break;
      case "Year":
        cards = allCards.filter(f => f.year === +myFilterValue)
        console.log("myFilterValue", +myFilterValue)
        //cards = allCards.filter(f => f.year.includes(+myFilterValue))
        break;
      case "Certification":
        //cards = allCards.filter(f => f.certification === myFilterValue)
        cards = allCards.filter(f => f.certification.includes(myFilterValue))
        break;
      default:
        console.log("default")
        cards = allCards
    }
    //cards = allCards.filter(f => f.year === +myFilterValue)
    console.log("cards::::", cards)

    // const arr = ['Geeks', 'gfg', 'GeeksforGeeks', 'abc'];
    // const substr = 'eks';
    // const subArr = arr.filter(str => str.includes(substr));

    // console.log(subArr);
  }
  else {
    cards = allCards
  }

  const togglePopup = () => {
    setShow(!show)
  }

  const openCgcWindow = card => {
    console.log("card:", card)
    if (window.confirm("Show this card on CGC?") === true) {
      window.open("https://www.cgccards.com/certlookup/" + card.certification)
    }
  }

  const filterCards = (filterType) => {
    setMyFilterType(filterType)
    let filterValue = prompt("Filter by " + filterType)
    console.log("filterValue", filterValue)
    setMyFilterValue(filterValue)
  }

  return (
    <>
      {!isPageWide && (
        <div className="list">
          <button onClick={togglePopup} className="btn" style={{ justifySelf: "end" }}>
            add
          </button>
          {cards &&
            cards.map(card => (
              <div key={card.id} className="card dark">
                <details>
                  <summary style={{ display: "flex" }}>
                    <span style={{ marginRight: "auto" }}>{card.name}</span>
                    <span>{String(card.certification).slice(-4)}</span>
                  </summary>
                  <p>{card.set}</p>
                  <p>{card.number}</p>
                  <Link style={{ justifySelf: "end" }} to={`/cards/${card.id}`}>
                    View
                  </Link>
                </details>
              </div>
            ))}
          {show ? <Create toggle={togglePopup} pageWide={isPageWide} /> : null}
        </div>
      )}

      {isPageWide && (
        <div className="list cards-list">
          <div className="header">
            <p onClick={() => filterCards("Name")}>Name</p>
            <p onClick={() => filterCards("Set")}>Set</p>
            <p onClick={() => filterCards("Number")}>Number</p>
            <p onClick={() => filterCards("Year")}>Year</p>
            <p>Grade</p>
            <p onClick={() => filterCards("Certification")}>Certification</p>
            <button onClick={togglePopup} className="btn" style={{ justifySelf: "end" }}>
              add
            </button>
          </div>
          {cards &&
            cards.map(card => (
              <div key={card.id} className="card dark">
                {/* <h4>{legoSet.name}</h4> */}
                <p>{card.name}</p>
                {/* <details>
              <summary>{card.name}</summary>
              <p>Content goes here...</p>
            </details> */}
                <p>{card.set}</p>
                <p>{card.number}</p>
                <p>{card.year}</p>
                <p>{card.grade}</p>
                <p className="link" onClick={() => openCgcWindow(card)}>
                  {card.certification}
                </p>
                {/* <p>{legoSet.number}</p>
            <p>{legoSet.theme}</p>
            <p>{legoSet.year}</p>
            <p>{legoSet.quantity}</p> */}
                {/* <button className="btn" style={{ justifySelf: "end" }}>
              view
            </button> */}

                <Link style={{ justifySelf: "end" }} to={`/cards/${card.id}`}>
                  View
                </Link>

                {/* <Link to={`/recipes/${legoSet.id}`}>Cook This</Link> */}
              </div>
            ))}
          {show ? <Create toggle={togglePopup} pageWide={isPageWide} /> : null}
        </div>
      )}
    </>
  )
}
