import { initializeApp } from "firebase/app"
//import { getDoc, doc, getFirestore, Timestamp } from "firebase/firestore"
import { getDoc, doc, getFirestore, Timestamp, updateDoc, arrayRemove } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyD6UDag3qY5J2u2_Zz_LZO8cnI-lxzj1l0",
  authDomain: "collection.renoescamilla.com",
  projectId: "collection-site-4985a",
  storageBucket: "collection-site-4985a.appspot.com",
  messagingSenderId: "648761711787",
  appId: "1:648761711787:web:c651750cbfc49e957febee"
}

// init firebase
initializeApp(firebaseConfig)

// init firestore
const db = getFirestore()

// init firebase auth
const auth = getAuth()

export { db, doc, getDoc, updateDoc, Timestamp, arrayRemove, auth }
