import { useCollection } from "../../hooks/useCollection"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useMediaQuery } from "../../hooks/useMediaQuery"

//styles
import "./CardWishlist.css"

import Create from "./Create"

export default function CardWishlist() {
  const { documents: cardWishlist } = useCollection("CardWishlist")
  let isPageWide = useMediaQuery("(min-width: 800px)")
  const [show, setShow] = useState(false)
  console.log("Card Wishlist:", cardWishlist)

  const togglePopup = () => {
    setShow(!show)
  }

  return (
    <>
      <div className="list card-wishlist-list">
        <div className="header">
          <p>Name</p>
          <p>Number</p>
          <p>Set</p>
          <button onClick={togglePopup} className="btn" style={{ justifySelf: "end" }}>
            add
          </button>
        </div>
        {cardWishlist && cardWishlist.map(card => (
          <div key={card.id} className="card dark">
            <p>{card.name}</p>
            <p>{card.number}</p>
            <p>{card.set}</p>
            <Link style={{ marginLeft: "auto" }}>edit</Link>
          </div>
        ))}
        {show ? <Create toggle={togglePopup} pageWide={isPageWide} /> : null}
      </div>

      {/* {!isPageWide && (
        <div className="list">

        </div>
      )}

      {isPageWide && (
        <div className="list cards-list">
          </div>
      )} */}
    </>
  )
}
