import { useCollection } from "../../hooks/useCollection"
import { useParams, useLocation } from "react-router-dom"
import { collection, doc, deleteDoc, updateDoc, addDoc, query, where, getDoc, documentId } from "firebase/firestore"

export default function LegoDetail() {
    const { id } = useParams()
    const { documents: lego } = useCollection("LegoSets", [documentId(), "==", id])

  return (
    <>
    {lego && lego.map(l => (
        <div key={l.id} className="list">
            <div className="card dark">
                <p>{l.name}</p>
                <p>{l.number}</p>
            </div>
        </div>
    ))}
    </>
  )
}
