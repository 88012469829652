import { useState } from "react"
import { db } from "../../firebase/config"
import { collection, doc, addDoc, query, where, getDocs } from "firebase/firestore"

//styles
//import "./Create.css"


export default function Tools() {
    const [input, setInput] = useState("")
    const [results, setResults] = useState("")

    const convertInput = () => {
        console.log("convertInput click")
        //var json = tsvToJson(input);
        var json = newConvertToJson(input)
        //$("#results").text(JSON.stringify(json, undefined, 4));
        setResults((JSON.stringify(json, undefined, 4)))
        //console.log("results:", results)
    }
    const tsvToJson = (tsvText) => {
        //Split all the text into seperate lines on new lines and carriage return feeds
        var allTextLines = tsvText.split(/\r\n|\n/);
        //Split per line on tabs and commas
        var headers = allTextLines[0].split(/\t|,/);
        var lines = [];

        for (var i = 1; i < allTextLines.length; i++) {
            var data = allTextLines[i].split(/\t|,/);

            if (data.length == headers.length) {

                var row = {};
                for (var j = 0; j < headers.length; j++) {
                    row[headers[j]] = data[j];
                }
                lines.push(row);
            }
        }
        return lines;
    }

    const newConvertToJson = (textToConvert) => {
        let textLines = textToConvert.split(/\r\n|\n/)
        console.log("textLines", textLines)

        for (let i = 0; i < textLines.length; i++) {
            let dataRow = textLines[i]
            console.log("dataRow:", dataRow)
            let game = dataRow.substring(0,7)
            console.log("game:", game)
            let year = dataRow.substring(8, 12)
            console.log("year", year)
            let name = dataRow.substring(13, dataRow.search(/\[[0-9]+\]/), 14) //variable.match(/\[[0-9]+\]/);
            console.log("name:", name)
        }
    } 



    return (
        <div className="list">
            <textarea id="data-input" style={{ minHeight: "200px" }} onChange={e => setInput(e.target.value)}></textarea>
            <button className="btn" onClick={convertInput}>Convert to JSON</button>

            <div id="results" style={{ color: "#9ff" }}>
                {results}
            </div>
        </div>



    )
}
