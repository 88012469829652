import { Link } from "react-router-dom"
import { useState } from "react"
//import { useTheme } from '../hooks/useTheme'

// styles
import "./LegoList.css"

import Create from "./Create"

export default function LegoList({ legoSets }) {
  //const { mode } = useTheme()

  const [show, setShow] = useState(false)

  const togglePop = () => {
    setShow(!show)
  }

  return (
    <div className="list lego-list">
      <div className="header">
        <p>Name</p>
        <p>Number</p>
        <p>Theme</p>
        <p>Year</p>
        <p>Qty</p>
        <button onClick={togglePop} className="btn" style={{ justifySelf: "end" }}>
          add
        </button>
      </div>
      {legoSets &&
        legoSets.map(legoSet => (
          <div key={legoSet.id} className="card dark">
            {/* <h4>{legoSet.name}</h4> */}
            <p>{legoSet.name}</p>
            <p>{legoSet.number}</p>
            <p>{legoSet.theme}</p>
            <p>{legoSet.year}</p>
            <p>{legoSet.quantity}</p>
            <Link style={{justifySelf: "end"}} to={`/lego/${legoSet.id}`}>
                  View
            </Link>
            {/* <button className="btn" style={{ justifySelf: "end" }}>
              edit
            </button> */}
            {/* <Link to={`/recipes/${legoSet.id}`}>Cook This</Link> */}
          </div>
        ))}
      {show ? <Create toggle={togglePop} /> : null}
    </div>
  )
}
