import React from "react"
import { useEffect } from "react"
import { GoogleButton } from "react-google-button"
import { UserAuth } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"

export default function Signin() {
  const { googleSignIn, user } = UserAuth()
  const navigate = useNavigate()

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user != null) {
      console.log("navigate")
      navigate('/cards')
    }
  }, [user, navigate])

  return (
    <div>
      <span style={{ display: "flex", paddingTop: "5em" }}>
        <span style={{ marginLeft: "auto", marginRight: "auto" }}>
          <h1 style={{ color: "white" }}>Sign in</h1>
          <GoogleButton onClick={handleGoogleSignIn} />
        </span>
      </span>
    </div>
  )
}
