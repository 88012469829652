import { useCollection } from "../../hooks/useCollection"
import { useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import { collection, addDoc, query, where, onSnapshot, deleteDoc, orderBy, or, limit, startAfter, getDocs } from "firebase/firestore"
import { db, doc, Timestamp } from "../../firebase/config"
import Searchbar from "../../components/Searchbar"


import CardsList from "../cards/CardsList"


export default function Search() {
    const location = useLocation()
    const page = location.state
    console.log("search Page: ", page)

    //const [queryTerm, setQueryTerm] = useState(null)
    const [cards, setCards] = useState(null)

    const queryString = useLocation().search
    const queryParams = new URLSearchParams(queryString)
    const querySearch = queryParams.get('q')
    //setQueryTerm(query)
    // console.log("QueryTerm", queryTerm)
    // console.log("query:", query)

    //const { documents: cards } = useCollection("Cards", ['name', "in", [queryT]])

    // //
    // // Query the first page of docs
    // const first = query(collection(db, "Cards"), orderBy("name"), limit(3))
    // //const documentSnapshots = await getDocs(first)
    // const documentSnapshots = getDocs(first)
    // console.log("documentSnapshots", documentSnapshots)

    // // Get the last visible document
    // const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // console.log("last", lastVisible);

    // // Construct a new query starting at this document,
    // // get the next 25 cities.
    // const next = query(collection(db, "cities"),
    //     orderBy("population"),
    //     startAfter(lastVisible),
    //     limit(25));
    // //

    useEffect(() => {
        let ref = collection(db, page)

        if (querySearch !== "") {

            if (page == "Cards") {
                ref = query(ref, or(where(...["search", "==", querySearch]),
                    or(where(...["name", "==", querySearch])),
                    or(where(...["grade", "==", querySearch]))
                ), limit(2), orderBy("name"))
            } else if (page == "Lego") {
                alert("Lego if block")
            }
            

        }

        const unsub = onSnapshot(ref, snapshot => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({ id: doc.id, ...doc.data() })
            })
            //results.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

            setCards(results)
            console.log("Results:", results)
        })

        return () => unsub()
    }, [querySearch])

    console.log("Cards:", cards)

    return (
        <div>
            <Searchbar page={page} />
            {cards && <CardsList cards={cards} />}

        </div>
    )
}
