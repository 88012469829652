import { useState } from "react"
import { db } from "../../firebase/config"
import { collection, doc, addDoc, query, where, getDocs } from "firebase/firestore"
import Select from "react-select"

export default function Create(props) {

    const [name, setName] = useState("")
    const [cardNumber, setCardNumber] = useState("")
    const [cardSet, setCardSet] = useState("")
    const [year, setYear] = useState("")

    const handleSubmit = async e => {
        e.preventDefault()
        // Save
        const ref = collection(db, "CardWishlist")

        let data = {
            name: name.trim(),
            number: cardNumber.trim(),
            set: cardSet.trim(),
            year: +year.trim()
        }
        console.log("DATA with search:", data)
        await addDoc(ref, data)

        // Close popup
        props.toggle()
    }

    const selectStyles = {
        option: styles => ({ ...styles, color: "#333" })
    }

    return (
        <div className="popup">
            <div className="popup-inner" style={props.pageWide === false ? { width: "80%" } : { width: "40%" }}>
                <span style={{ display: "flex", flexDirection: "row-reverse", cursor: "pointer", fontSize: "1.2em" }} onClick={props.toggle}>X</span>
                <form onSubmit={handleSubmit}>
                    <label>
                        Name:
                        <input type="text" value={name} onChange={e => setName(e.target.value)} />
                    </label>
                    <label>
                        Card Number:
                        <input type="text" value={cardNumber} onChange={e => setCardNumber(e.target.value)} />
                    </label>
                    <label>
                        Card Set:
                        <input type="text" value={cardSet} onChange={e => setCardSet(e.target.value)} />
                    </label>
                    <label>
                        Year:
                        <input type="number" value={year} onChange={e => setYear(e.target.value)} />
                    </label>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
