import { useCollection } from "../../hooks/useCollection"
import { useParams, useLocation } from "react-router-dom"
import { collection, doc, deleteDoc, updateDoc, addDoc, query, where, getDoc, documentId } from "firebase/firestore"

export default function CardDetail() {
    const { id } = useParams()
    const { documents: card } = useCollection("Cards", [documentId(), "==", id])

    console.log("Card Detail: ", card)

  return (
    <>
    {card && card.map(c => (
        <div key={c.id} className="list">
            <div className="card dark">
            <p>{c.name}</p>
            <p>{c.certification}</p>
            </div>
        </div>
    ))}
    </>
  )
}
