import { useState } from "react"
import { db } from "../../firebase/config"
import { collection, doc, addDoc, query, where, getDocs } from "firebase/firestore"
import Select from "react-select"
import { languageOptions, gradingCompanyOptions } from "../../Constants"

//styles
//import "./Create.css"

export default function Create(props) {
  console.log("Create props: ", props)
  const [name, setName] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [cardSet, setCardSet] = useState("")
  const [year, setYear] = useState("")
  const [game, setGame] = useState("Pokemon")
  const [gradingCompany, setGradingCompany] = useState("CGC")
  const [grade, setGrade] = useState("")
  const [certification, setCertification] = useState("")
  const [language, setLanguage] = useState("English")
  const [variants, setVariants] = useState("")
  const [errorType, setErrorType] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    // Save
    const ref = collection(db, "Cards")

    // let dateFormat = new Date(dueDate)
    // dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    // let dateStr = dateFormat.toDateString()

    let data = {
      name: name.trim(),
      number: cardNumber.trim(),
      set: cardSet.trim(),
      year: +year.trim(),
      game: game.trim(),
      gradingCompany: gradingCompany,
      grade: grade.trim(),
      certification: +certification.trim(),
      language: language,
      variants: variants.trim(),
      errorType: errorType.trim(),
      search: name.substring(0, name.trim().length >= 5 ? 5 : name.trim().length).trim()
    }
    console.log("DATA with search:", data)
    await addDoc(ref, data)

    // Close popup
    props.toggle()
  }

  const testCardsSubmit = () => {
    console.log("click")
    // console.log("testCards:", testCards)
    // console.table(testCards)
  }

  const selectStyles = {
    option: styles => ({ ...styles, color: "#333" })
  }

  return (
    <div className="popup">
      <div className="popup-inner" style={props.pageWide === false ? {width: "80%"} : {width: "40%"}}>
        <span style={{display: "flex", flexDirection: "row-reverse", cursor: "pointer", fontSize: "1.2em"}} onClick={props.toggle}>X</span>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" value={name} onChange={e => setName(e.target.value)} />
          </label>
          <label>
            Card Number:
            <input type="text" value={cardNumber} onChange={e => setCardNumber(e.target.value)} />
          </label>
          <label>
            Card Set:
            <input type="text" value={cardSet} onChange={e => setCardSet(e.target.value)} />
          </label>
          <label>
            Year:
            <input type="number" value={year} onChange={e => setYear(e.target.value)} />
          </label>
          <label>
            Game:
            <input type="text" value={game} onChange={e => setGame(e.target.value)} />
          </label>
          <label>
            Grading Company:
            {/* <input type="text" value={gradingCompany} onChange={e => setGradingCompany(e.target.value)} /> */}
            <Select options={gradingCompanyOptions} onChange={option => setGradingCompany(option.value)} defaultValue={{ value: "CGC", label: "CGC" }} styles={selectStyles} />
          </label>
          <label>
            Grade:
            <input type="text" value={grade} onChange={e => setGrade(e.target.value)} />
          </label>
          <label>
            Certification Number:
            <input type="text" value={certification} onChange={e => setCertification(e.target.value)} />
          </label>
          <label>
            Language:
            {/* <input type="text" value={language} onChange={e => setLanguage(e.target.value)} /> */}
            {/* <Select options={languageOptions} onChange={option => setLanguage(option.value)} defaultValue={{ value: "English", label: "English" }} styles={selectStyles}/> */}
            <Select options={languageOptions} onChange={option => setLanguage(option.value)} defaultValue={{ value: "English", label: "English" }} styles={selectStyles} />
          </label>
          <label>
            Variants:
            <input type="text" value={variants} onChange={e => setVariants(e.target.value)} />
          </label>
          <label>
            Error Type:
            <input type="text" value={errorType} onChange={e => setErrorType(e.target.value)} />
          </label>
          <div style={{display:"flex", justifyContent: "flex-end"}}>
            <button type="submit">Submit</button>
          </div>
        </form>
        <button type="button" onClick={testCardsSubmit}>
          test
        </button>
      </div>
    </div>
  )
}
